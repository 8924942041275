import { Title } from '@solidjs/meta';
import { createAsync, useNavigate, useParams, useSearchParams } from '@solidjs/router';
import { cachedGet } from '@troon/api-client';
import { ActivityIndicator, Button, Heading, Input, Label, Link, TextField, TextLink } from '@troon/ui';
import debounce from 'debounce';
import { createMemo, For, Show, Suspense } from 'solid-js';
import { IconSearchMagnifyingGlass } from '@troon/icons/search-magnifying-glass';
import { IconUserAdd } from '@troon/icons/user-add';
import { twJoin } from '@troon/tailwind-preset/merge';
import dayjs from '@troon/dayjs';
import { IconCalendar } from '@troon/icons/calendar';
import { IconChevronDown } from '@troon/icons/chevron-down';
import { Content } from '../../../../../components/main-content';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../../components/table';
import { TroonAccessTag } from '../../../../../components/troon-access-tag';
import { useCurrentFacility } from '../../../../../providers/root';
import type { ApiResponse } from '@troon/api-client';
import type { RouteSectionProps } from '@solidjs/router';

export default function Customers(props: RouteSectionProps) {
	const [searchParams, setSearchParams] = useSearchParams<{ query?: string }>();
	const facility = useCurrentFacility();

	const updateQuery = debounce((value: string) => {
		setSearchParams({ query: value });
	}, 500);

	return (
		<Content class="gap-12">
			<Title>Search for Customers | Troon Operator</Title>
			<div class="flex items-center justify-between">
				<Heading as="h1">Customers</Heading>
				<Button as={Link} href={`/facility/${props.params.facility!}/customer/new`} class="size-fit grow-0">
					<IconUserAdd /> New User
				</Button>
			</div>

			<TextField name="query" class="w-full">
				<Label>Search for a customer</Label>
				<Input
					type="search"
					onInput={(e) => {
						updateQuery(e.target.value);
					}}
					value={searchParams.query || ''}
					class="p-6 ps-12"
					placeholder="Search by name, email, phone or rewards number"
					prefixElement={<IconSearchMagnifyingGlass class="mx-2 text-brand" />}
				/>
			</TextField>

			<Show when={searchParams.query}>
				<SearchResults query={searchParams.query} facilityId={props.params.facility!} />
			</Show>

			<Show when={!searchParams.query && facility()?.facility.isBookable}>
				<FacilityReservations facilityId={props.params.facility!} />
			</Show>
		</Content>
	);
}

function SearchResults(props: { query: string | undefined; facilityId: string }) {
	const navigate = useNavigate();

	const results = createAsync(async () => {
		const q = (props.query as string) ?? undefined;
		if (q) {
			return searchUsers({ query: { query: q } });
		}
		return null;
	});

	return (
		<div class="flex flex-col gap-4">
			<div class="flex flex-wrap items-end justify-between gap-4">
				<Heading as="h2" size="h4">
					Results
				</Heading>
			</div>

			<Table>
				<Thead>
					<Tr>
						<Th>Name</Th>
						<Th>Rewards ID</Th>
						<Th>Email address</Th>
						<Th>Rewards Level</Th>
						<Th>Access</Th>
						<Th>Location</Th>
					</Tr>
				</Thead>
				<Tbody>
					<Suspense
						fallback={
							<tr>
								<td colspan={6} class="text-center italic text-neutral-700">
									<ActivityIndicator />
								</td>
							</tr>
						}
					>
						<Show
							when={results.latest?.users}
							fallback={
								<Tr>
									<Td colspan={6} class="text-center italic text-neutral-700">
										<Show when={!props.query} fallback={<ActivityIndicator />}>
											Enter a search query to get started.
										</Show>
									</Td>
								</Tr>
							}
						>
							<For
								each={results()?.users}
								fallback={
									<Tr>
										<Td colspan={6} class="rounded-b border border-t-0 border-neutral bg-white p-8 text-center">
											No results for <q class="font-medium">{props.query}</q>.
										</Td>
									</Tr>
								}
							>
								{(user) => (
									<Tr
										class="group cursor-pointer"
										onClick={() => {
											navigate(`/facility/${props.facilityId}/customer/${user.rewardsId}`, {
												state: { query: props.query },
											});
										}}
									>
										<Th scope="row">
											<Link
												href={`/facility/${props.facilityId}/customer/${user.rewardsId}`}
												state={{ query: props.query }}
												class="inset-0 after:absolute"
											>
												{user.firstName} {user.lastName}
											</Link>
										</Th>
										<Td>{user.rewardsId}</Td>
										<Td>{user.email}</Td>
										<Td>{user.rewardsLevel}</Td>
										<Td>
											<TroonAccessTag product={user.troonAccessProductType} />
										</Td>
										<Td>{[user.city, user.state, user.country].filter(Boolean).join(', ')}</Td>
									</Tr>
								)}
							</For>
						</Show>
					</Suspense>
				</Tbody>
			</Table>
		</div>
	);
}

function FacilityReservations(props: { facilityId: string }) {
	const [searchParams, setSearchParams] = useSearchParams<{ date?: string }>();

	const updateSearchParams = (value: string) => {
		setSearchParams({ date: value });
	};

	const reservations = createAsync(async () => {
		return facilityReservations({
			path: { facilityId: props.facilityId },
			query: { date: searchParams.date ?? dayjs().format('YYYY-MM-DD') },
		});
	});

	// Only show courses if there are multiple courses in the reservations
	const showCourses = createMemo(() => {
		return (
			(reservations()?.reservations ?? []).reduce((acc, reservation) => {
				acc.add(reservation.course.name);
				return acc;
			}, new Set<string>()).size > 1
		);
	});

	return (
		<div class="flex flex-col gap-4">
			<div class="flex items-center justify-between gap-8">
				<div class="flex flex-col gap-2">
					<Heading as="h2" size="h4">
						Upcoming Tee Times
					</Heading>
					<p class="text-sm text-neutral-700">
						The list below only shows tee times that were booked on <TroonDotComLink /> or the Troon Golf iOS App. If
						you don't see a customer's reservation, encourage them to book on <TroonDotComLink /> to get the best rates
					</p>
				</div>
				<TextField name="date">
					<Input
						prefixElement={<IconCalendar class="mx-2 text-brand" />}
						suffixElement={<IconChevronDown class="mx-2 text-brand" />}
						type="date"
						value={searchParams.date ?? dayjs().format('YYYY-MM-DD')}
						class="pe-8 ps-12"
						onChange={(e) => {
							updateSearchParams(e.target.value);
						}}
					/>
				</TextField>
			</div>

			<Suspense fallback={<ActivityIndicator />}>
				<Show when={reservations()}>
					<Table>
						<Thead>
							<Tr>
								<Th>Tee Time</Th>
								<Th>Name</Th>
								<Th class="text-center">Rate</Th>
								<Th class="text-center">Troon Access</Th>
								<Th class="text-center">Players</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Show when={reservations()?.reservations.length === 0}>
								<Tr>
									<Td colspan={6} class="rounded-b border border-t-0 border-neutral bg-white p-8">
										No tee times were booked on <TroonDotComLink />. Make sure to remind customers they can book
										directly on <TroonDotComLink /> to get the best available rates!
									</Td>
								</Tr>
							</Show>
							<For each={reservations()?.reservations}>
								{(reservation) => <FacilityReservationRow reservation={reservation} showCourses={showCourses()} />}
							</For>
						</Tbody>
					</Table>
				</Show>
			</Suspense>
		</div>
	);
}

function TroonDotComLink() {
	return (
		<TextLink class="font-medium" href="https://troon.com">
			troon.com
		</TextLink>
	);
}

function FacilityReservationRow(props: {
	reservation: ApiResponse<'get', '/v0/operator/facilities/{facilityId}/reservations'>['reservations'][number];
	showCourses: boolean;
}) {
	const params = useParams<{ facility: string }>();
	const playerCount = createMemo(() => props.reservation.users.length);
	return (
		<>
			<For each={props.reservation.users}>
				{(user, index) => (
					<Tr>
						<Td subRow={index() < props.reservation.users.length - 1}>
							<Show when={index() === 0}>
								<div class="flex flex-col items-start justify-start gap-1">
									<p class="text-base font-medium">
										{dayjs(props.reservation.teeTime.epoch).tz(props.reservation.teeTime.tz).format('h:mm a')}
									</p>
									{props.showCourses && <p class="text-sm text-neutral-700">{props.reservation.course.name}</p>}
								</div>
							</Show>
						</Td>
						<Td
							subRow={index() < props.reservation.users.length - 1}
							class={twJoin('text-base', !user.user && 'text-neutral-700')}
						>
							<Show when={user.user} fallback={<p class="text-neutral-700">Guest</p>}>
								<TextLink href={`/facility/${params.facility}/customer/${user.user!.rewardsId}`}>
									{user.user!.firstName} {user.user!.lastName}
								</TextLink>
							</Show>
						</Td>
						<Td subRow={index() < props.reservation.users.length - 1} class="text-center">
							{user.rate.displayValue}
						</Td>
						<Td subRow={index() < props.reservation.users.length - 1} class="text-center">
							<Show when={user.user?.troonAccessProductType} fallback={<p class="text-sm text-neutral-700">-</p>}>
								<TroonAccessTag product={user.user!.troonAccessProductType} guestPass={user.guestPass} />
							</Show>
						</Td>
						<Td subRow={index() < props.reservation.users.length - 1} class="text-center">
							<Show when={index() === 0}>{playerCount()}</Show>
						</Td>
					</Tr>
				)}
			</For>
		</>
	);
}

const searchUsers = cachedGet('/v0/operator/users/search', {});
const facilityReservations = cachedGet('/v0/operator/facilities/{facilityId}/reservations', {});
